const envScript = document.head.querySelector("[data-env]");
Object.assign(window, JSON.parse(envScript.innerText));
envScript.remove();

Object.prototype.map = function(mapper) {
	return Object.fromEntries(Object.entries(this).map(mapper));
};

const localeNumberParts = Object.fromEntries(
	new Intl.NumberFormat($LOCALE_INTL, {useGrouping: true}).formatToParts(-1234.5)
	.filter(({type}) => ["group", "decimal"].includes(type))
	.map(({type, value}) => [type, value])
);

String.prototype.toCamelCase =  function() { return this.replace(/[^\w]+(.)/g, (match, char) => char.toUpperCase()); };
String.prototype.toKebabCase =  function() { return this.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(); };
String.prototype.toPascalCase = function() { return this.toCamelCase().replace(/^\w/, char => char.toUpperCase()); };

String.fromInput = (input, default_, empty=false) => !!input?.value.length || empty ? String(input.value) : default_;
Number.fromInput = (input, default_) => {
	if(!input?.value.length) {
		return default_;
	}
	// Remove all grouping characters and replace any decimal delimiter with a period, then parse as a number:
	return Number(input.value.replaceAll(localeNumberParts.group, "").replace(localeNumberParts.decimal, "."));
};

// Prototype extension to make XPathResult iterable:
// Source: https://www.anycodings.com/1questions/1320706/how-to-use-arrayfrom-with-a-xpathresult
XPathResult.prototype[Symbol.iterator] = function* () {
	switch(this.resultType) {
		case XPathResult.UNORDERED_NODE_ITERATOR_TYPE:
		case XPathResult.ORDERED_NODE_ITERATOR_TYPE:
			let result;
			while(result = this.iterateNext()) {
				yield result;
			}
			break;
		case XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE:
		case XPathResult.ORDERED_NODE_SNAPSHOT_TYPE:
			for(let i=0; i < this.snapshotLength; i++) {
				yield this.snapshotItem(i);
			}
			break;
		default:
			yield this.singleNodeValue;
			break;
	}
};

DOMStringMap.prototype.add =    function(...keys) { keys.forEach(key => this[key] = ""); };
DOMStringMap.prototype.remove = function(...keys) { keys.forEach(key => delete this[key]); };
DOMStringMap.prototype.clear =  function() { Object.keys(this).forEach(key => delete this[key]); };
DOMStringMap.prototype.toggle = function(toggle, add, remove) {
	this[toggle  ? "add" : "remove"](add);
	remove != null && this[!toggle ? "add" : "remove"](remove);
};

if(HTMLElement.prototype.scrollIntoViewIfNeeded == null) {
	HTMLElement.prototype.scrollIntoViewIfNeeded = function(center=true) {
		this.scrollIntoView(!center);
	};
}

HTMLElement.prototype.show = function(toggle=true) { this.hidden = !toggle; };
HTMLElement.prototype.hide = function() { this.hidden = true; };

HTMLFieldSetElement.prototype.reset = function() {
	Array.from(this.elements).forEach(input => input.value = input.defaultValue);
	this.dispatchEvent(new Event("reset", {bubbles: true}));
};

window.sleep = (delay, callback, signal) => {
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(
			() => {
				callback != null && callback();
				resolve();
			},
			delay
		);
		signal?.addEventListener("abort", () => {
			clearTimeout(timeout);
			reject(signal.reason);
		});
	});
};

window.debounce = (delay, callback) => {
	let timeout;
	return () => {
		clearTimeout(timeout);
		timeout = setTimeout(callback, delay);
	};
}
