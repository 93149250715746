import {fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class AttendeePage extends Component {

	constructor($, $$) {
		super(...arguments, {template: new Template($$.template)});
		this.render();
	}

	async render() {
		const id = location.pathname.split("/")[2];
		const url = new URL(`/api/attendees/${id}`, location.origin);
		try {
			const [data] = await Promise.all([fetchJSON("GET", url, {throwOn404: true}), sleep(500)]);
			this.data = data;
		}
		catch(error) {
			this.dispatch("error", {error});
			throw(error);
		}
		this.template(this.$$.canvas, {attendee: this.data}, "replaceWith");
	}

}
