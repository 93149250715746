import * as constructors from "./components/__index__.js";

for(const obj of [document, HTMLElement.prototype]) {
	Object.defineProperty(obj, "component", {
		get: function() {
			return components.get(this);
		}
	});
}

const getElements = (function(root, alias) {
	const query = `.//*[@*[name()="@${alias}" or starts-with(name(), "@${alias}:")]]`;
	return Array.from(this.evaluate(query, root, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE));
})
.bind(new XPathEvaluator());

// Attribute parsing helper:
const getAttr = ($, alias) => $.removeAttributeNode(
	Array.from($.attributes).find(({name}) => name == `@${alias}` || name.startsWith(`@${alias}:`))
);

// Object deep assignment helper:
const setAtPath = (obj, path, value) => {
	const keys = path.replace(/\[(\d+)\]/g, ".$1").split(".");
	const key = keys.pop();
	const obj_ = keys.reduce((obj_, key_) => obj_ = obj_[key_] ?? (obj_[key_] = key.length ? {} : []), obj);
	obj_[key.length ? key : obj_.length] = value;
};

const instantiate = () => {
	for(const $ of getElements(document, "").reverse()) {
		const attr = getAttr($, "");
		const [_, alias=attr.value] = attr.name.split(":");
		const $$ = {};
		for(const $_ of getElements($, alias)) {
			const attr = getAttr($_, alias);
			const [_, path=($_.component.__alias__ ?? $_.component.constructor.name.toKebabCase())] = attr.name.split(":");
			setAtPath($$, path.split(".").map(key => key.toCamelCase()).join("."), $_);
		};
		const name = attr.value.toPascalCase();
		if(!(name in constructors)) {
			throw `Constructor for [${name}] does not exist`;
		}
		const component = new constructors[name]($, $$);
		component.__alias__ = alias;
		components.set($, component);
	}
};

const components = new WeakMap();

// Instantiate all components:
instantiate();

// Instantiate any new components when they are added to the document:
new MutationObserver(mutations => mutations.some(mutation => !!mutation.addedNodes.length) && instantiate())
.observe(document, {childList: true, subtree: true});
