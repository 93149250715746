import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class PaymentForm extends Form {

	constructor($, $$) {
		super(...arguments);
		listen($, "change", () => this.setMode());
	}

	setMode() {
		switch(this.$.elements.mode.value) {
			case "online":
				this.$$.messages.online.show();
				this.$$.messages.invoice.hide();
				break;
			case "invoice":
				this.$$.messages.online.hide();
				this.$$.messages.invoice.show();
				break;
		}
	}

	serialize() {
		// @todo: Offer online payment method and issuer pre-selection?
		return {mode: this.$.elements.mode?.value};
	}

	async submit() {

		this.$$.submit.dataset.add("pending");
		try {
			// @todo: Gracefully handle a 409 response. Somehow...
			await Promise.all([super.submit(), sleep(500)]);
		}
		finally {
			this.$$.submit.dataset.remove("pending");
		}

		if(this.data?.checkoutUrl != null) {
			location.assign(this.data.checkoutUrl);
			return;
		}

		location.assign(
			this.data?.checkoutUrl != null
			? new URL(this.data.checkoutUrl)
			: new URL(`/orders/${location.pathname.split("/")[2]}/attendees`, location.origin)
		);

	}

}
