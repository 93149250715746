import {fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class PaymentPage extends Component {

	constructor($, $$) {
		super(...arguments, {templates: $$.templates.map(([key, template]) => [key, new Template(template)])});
		this.render();
	}

	async render() {

		// Wait for the webhook to be processed when returning from Mollie:
		const params = new URLSearchParams(location.search);
		if(params.has("redirect")) {
			history.replaceState({}, null, location.pathname);
			await sleep(2_500);
		}

		const id = location.pathname.split("/")[2];
		const url = new URL(`/api/orders/${id}`, location.origin);
		try {
			const [data] = await Promise.all([fetchJSON("GET", url, {throwOn404: true}), sleep(500)]);
			this.data = data;
		}
		catch(error) {
			this.dispatch("error", {error});
			throw(error);
		}

		const paymentComplete = (this.data.paymentAmount == 0) || this.data.paymentComplete;
		const attendeesComplete = this.data.categories.every(
			category => category.tickets.every(ticket => ticket.attendeeId != null)
		);
		this.$$.attendeesLink.href = `/orders/${id}/attendees`;
		this.$$.steps[0].dataset.add("complete");
		paymentComplete && sleep(200, () => this.$$.steps[1].dataset.add("complete"));
		attendeesComplete && sleep(paymentComplete ? 400 : 200, () => this.$$.steps[2].dataset.add("complete"));

		this.templates.card(this.$$.canvases.card, this.data);
		this.templates.form(this.$$.canvases.form, this.data, "replaceWith");

	}

}
